const mappedEmojis = {
  AF: "🇦🇫",
  AL: "🇦🇱",
  DZ: "🇩🇿",
  AD: "🇦🇩",
  AO: "🇦🇴",
  AI: "🇦🇮",
  AQ: "🇦🇶",
  AG: "🇦🇬",
  AR: "🇦🇷",
  AM: "🇦🇲",
  AW: "🇦🇼",
  AU: "🇦🇺",
  AT: "🇦🇹",
  AZ: "🇦🇿",
  BS: "🇧🇸",
  BH: "🇧🇭",
  BD: "🇧🇩",
  BB: "🇧🇧",
  BY: "🇧🇾",
  BE: "🇧🇪",
  BZ: "🇧🇿",
  BJ: "🇧🇯",
  BM: "🇧🇲",
  BT: "🇧🇹",
  BO: "🇧🇴",
  BA: "🇧🇦",
  BW: "🇧🇼",
  BR: "🇧🇷",
  BN: "🇧🇳",
  BG: "🇧🇬",
  BF: "🇧🇫",
  BI: "🇧🇮",
  KH: "🇰🇭",
  CM: "🇨🇲",
  CA: "🇨🇦",
  CV: "🇨🇻",
  KY: "🇰🇾",
  CF: "🇨🇫",
  TD: "🇹🇩",
  CL: "🇨🇱",
  CN: "🇨🇳",
  CO: "🇨🇴",
  KM: "🇰🇲",
  CG: "🇨🇬",
  CK: "🇨🇰",
  CR: "🇨🇷",
  HR: "🇭🇷",
  CU: "🇨🇺",
  CY: "🇨🇾",
  CZ: "🇨🇿",
  DK: "🇩🇰",
  DJ: "🇩🇯",
  DM: "🇩🇲",
  DO: "🇩🇴",
  EC: "🇪🇨",
  EG: "🇪🇬",
  SV: "🇸🇻",
  GQ: "🇬🇶",
  EE: "🇪🇪",
  ET: "🇪🇹",
  FK: "🇫🇰",
  FJ: "🇫🇯",
  FI: "🇫🇮",
  FR: "🇫🇷",
  GF: "🇬🇫",
  GA: "🇬🇦",
  GM: "🇬🇲",
  GE: "🇬🇪",
  DE: "🇩🇪",
  GH: "🇬🇭",
  GI: "🇬🇮",
  GR: "🇬🇷",
  GL: "🇬🇱",
  GD: "🇬🇩",
  GP: "🇬🇵",
  GT: "🇬🇹",
  GN: "🇬🇳",
  GW: "🇬🇼",
  GY: "🇬🇾",
  HT: "🇭🇹",
  HN: "🇭🇳",
  HK: "🇭🇰",
  HU: "🇭🇺",
  IS: "🇮🇸",
  IN: "🇮🇳",
  ID: "🇮🇩",
  IR: "🇮🇷",
  IQ: "🇮🇶",
  IE: "🇮🇪",
  IL: "🇮🇱",
  IT: "🇮🇹",
  CI: "🇨🇮",
  JM: "🇯🇲",
  JP: "🇯🇵",
  JO: "🇯🇴",
  KZ: "🇰🇿",
  KE: "🇰🇪",
  KI: "🇰🇮",
  KW: "🇰🇼",
  KG: "🇰🇬",
  LA: "🇱🇦",
  LV: "🇱🇻",
  LB: "🇱🇧",
  LS: "🇱🇸",
  LR: "🇱🇷",
  LY: "🇱🇾",
  LI: "🇱🇮",
  LT: "🇱🇹",
  LU: "🇱🇺",
  MO: "🇲🇴",
  MK: "🇲🇰",
  MG: "🇲🇬",
  MW: "🇲🇼",
  MY: "🇲🇾",
  MV: "🇲🇻",
  ML: "🇲🇱",
  MT: "🇲🇹",
  MH: "🇲🇭",
  MQ: "🇲🇶",
  MR: "🇲🇷",
  MU: "🇲🇺",
  MX: "🇲🇽",
  MD: "🇲🇩",
  MC: "🇲🇨",
  MN: "🇲🇳",
  MS: "🇲🇸",
  MA: "🇲🇦",
  MZ: "🇲🇿",
  MM: "🇲🇲",
  NA: "🇳🇦",
  NR: "🇳🇷",
  NP: "🇳🇵",
  NL: "🇳🇱",
  AN: "🇦🇳",
  NC: "🇳🇨",
  NZ: "🇳🇿",
  NI: "🇳🇮",
  NE: "🇳🇪",
  NG: "🇳🇬",
  KP: "🇰🇵",
  NO: "🇳🇴",
  OM: "🇴🇲",
  PK: "🇵🇰",
  PA: "🇵🇦",
  PG: "🇵🇬",
  PY: "🇵🇾",
  PE: "🇵🇪",
  PH: "🇵🇭",
  PL: "🇵🇱",
  PT: "🇵🇹",
  QA: "🇶🇦",
  RE: "🇷🇪",
  RO: "🇷🇴",
  RU: "🇷🇺",
  RW: "🇷🇼",
  LC: "🇱🇨",
  VC: "🇻🇨",
  WS: "🇼🇸",
  SM: "🇸🇲",
  SA: "🇸🇦",
  SN: "🇸🇳",
  SC: "🇸🇨",
  SL: "🇸🇱",
  SG: "🇸🇬",
  SK: "🇸🇰",
  SI: "🇸🇮",
  SB: "🇸🇧",
  SO: "🇸🇴",
  ZA: "🇿🇦",
  KR: "🇰🇷",
  ES: "🇪🇸",
  LK: "🇱🇰",
  SD: "🇸🇩",
  SR: "🇸🇷",
  SZ: "🇸🇿",
  SE: "🇸🇪",
  CH: "🇨🇭",
  SY: "🇸🇾",
  TJ: "🇹🇯",
  TW: "🇹🇼",
  TZ: "🇹🇿",
  TH: "🇹🇭",
  TG: "🇹🇬",
  TO: "🇹🇴",
  TT: "🇹🇹",
  TN: "🇹🇳",
  TR: "🇹🇷",
  TM: "🇹🇲",
  TV: "🇹🇻",
  UG: "🇺🇬",
  UA: "🇺🇦",
  AE: "🇦🇪",
  GB: "🇬🇧",
  US: "🇺🇸",
  UY: "🇺🇾",
  UZ: "🇺🇿",
  VU: "🇻🇺",
  VA: "🇻🇦",
  VE: "🇻🇪",
  VN: "🇻🇳",
  VG: "🇻🇬",
  YE: "🇾🇪",
  ZM: "🇿🇲",
  ZW: "🇿🇼",
  CD: "🇨🇩",
  ER: "🇪🇷",
  PF: "🇵🇫",
  KN: "🇰🇳",
  TC: "🇹🇨",
  RS: "🇷🇸",
  TL: "🇹🇱",
  ME: "🇲🇪",
  IM: "🇮🇲",
  PS: "🇵🇸",
  CW: "🇨🇼",
  GG: "🇬🇬",
  JE: "🇯🇪",
}

const mappedCountryIds = {
  1: {
    name: "Afghanistan",
    code: "AF",
  },
  2: {
    name: "Albania",
    code: "AL",
  },
  3: {
    name: "Algeria",
    code: "DZ",
  },
  5: {
    name: "Andorra",
    code: "AD",
  },
  6: {
    name: "Angola",
    code: "AO",
  },
  7: {
    name: "Anguilla",
    code: "AI",
  },
  8: {
    name: "Antarctica",
    code: "AQ",
  },
  9: {
    name: "Antigua & Barbuda",
    code: "AG",
  },
  10: {
    name: "Argentina",
    code: "AR",
  },
  11: {
    name: "Armenia",
    code: "AM",
  },
  12: {
    name: "Aruba",
    code: "AW",
  },
  13: {
    name: "Australia",
    code: "AU",
  },
  14: {
    name: "Austria",
    code: "AT",
  },
  15: {
    name: "Azerbaijan",
    code: "AZ",
  },
  16: {
    name: "Bahamas",
    code: "BS",
  },
  17: {
    name: "Bahrain",
    code: "BH",
  },
  18: {
    name: "Bangladesh",
    code: "BD",
  },
  19: {
    name: "Barbados",
    code: "BB",
  },
  20: {
    name: "Belarus",
    code: "BY",
  },
  21: {
    name: "Belgium",
    code: "BE",
  },
  22: {
    name: "Belize",
    code: "BZ",
  },
  23: {
    name: "Benin",
    code: "BJ",
  },
  24: {
    name: "Bermuda",
    code: "BM",
  },
  25: {
    name: "Bhutan",
    code: "BT",
  },
  26: {
    name: "Bolivia",
    code: "BO",
  },
  27: {
    name: "Bosnia-Herzegovina",
    code: "BA",
  },
  28: {
    name: "Botswana",
    code: "BW",
  },
  30: {
    name: "Brazil",
    code: "BR",
  },
  31: {
    name: "Brunei",
    code: "BN",
  },
  32: {
    name: "Bulgaria",
    code: "BG",
  },
  33: {
    name: "Burkina Faso",
    code: "BF",
  },
  34: {
    name: "Burundi",
    code: "BI",
  },
  35: {
    name: "Cambodia",
    code: "KH",
  },
  36: {
    name: "Cameroon",
    code: "CM",
  },
  37: {
    name: "Canada",
    code: "CA",
  },
  38: {
    name: "Cape Verde",
    code: "CV",
  },
  39: {
    name: "Cayman Islands",
    code: "KY",
  },
  40: {
    name: "Central African Republic",
    code: "CF",
  },
  41: {
    name: "Chad",
    code: "TD",
  },
  42: {
    name: "Chile",
    code: "CL",
  },
  43: {
    name: "China",
    code: "CN",
  },
  46: {
    name: "Colombia",
    code: "CO",
  },
  47: {
    name: "Comoros",
    code: "KM",
  },
  48: {
    name: "Republic of the Congo",
    code: "CG",
  },
  49: {
    name: "Cook Islands",
    code: "CK",
  },
  50: {
    name: "Costa Rica",
    code: "CR",
  },
  51: {
    name: "Croatia",
    code: "HR",
  },
  52: {
    name: "Cuba",
    code: "CU",
  },
  53: {
    name: "Cyprus",
    code: "CY",
  },
  54: {
    name: "Czech Republic",
    code: "CZ",
  },
  55: {
    name: "Denmark",
    code: "DK",
  },
  56: {
    name: "Djibouti",
    code: "DJ",
  },
  57: {
    name: "Dominica",
    code: "DM",
  },
  58: {
    name: "Dominican Republic",
    code: "DO",
  },
  60: {
    name: "Ecuador",
    code: "EC",
  },
  61: {
    name: "Egypt",
    code: "EG",
  },
  62: {
    name: "El Salvador",
    code: "SV",
  },
  63: {
    name: "Equatorial Guinea",
    code: "GQ",
  },
  64: {
    name: "Estonia",
    code: "EE",
  },
  65: {
    name: "Ethiopia",
    code: "ET",
  },
  66: {
    name: "Falkland Islands",
    code: "FK",
  },
  68: {
    name: "Fiji",
    code: "FJ",
  },
  69: {
    name: "Finland",
    code: "FI",
  },
  71: {
    name: "France",
    code: "FR",
  },
  72: {
    name: "French Guiana",
    code: "GF",
  },
  73: {
    name: "Gabon",
    code: "GA",
  },
  74: {
    name: "Gambia",
    code: "GM",
  },
  75: {
    name: "Georgia",
    code: "GE",
  },
  76: {
    name: "Germany",
    code: "DE",
  },
  77: {
    name: "Ghana",
    code: "GH",
  },
  78: {
    name: "Gibraltar",
    code: "GI",
  },
  79: {
    name: "Greece",
    code: "GR",
  },
  80: {
    name: "Greenland",
    code: "GL",
  },
  81: {
    name: "Grenada",
    code: "GD",
  },
  82: {
    name: "Guadeloupe",
    code: "GP",
  },
  84: {
    name: "Guatemala",
    code: "GT",
  },
  85: {
    name: "Guinea",
    code: "GN",
  },
  86: {
    name: "Guinea-Bissau",
    code: "GW",
  },
  87: {
    name: "Guyana",
    code: "GY",
  },
  88: {
    name: "Haiti",
    code: "HT",
  },
  89: {
    name: "Honduras",
    code: "HN",
  },
  90: {
    name: "Hong Kong",
    code: "HK",
  },
  91: {
    name: "Hungary",
    code: "HU",
  },
  92: {
    name: "Iceland",
    code: "IS",
  },
  93: {
    name: "India",
    code: "IN",
  },
  94: {
    name: "Indonesia",
    code: "ID",
  },
  95: {
    name: "Iran",
    code: "IR",
  },
  96: {
    name: "Iraq",
    code: "IQ",
  },
  97: {
    name: "Ireland",
    code: "IE",
  },
  98: {
    name: "Israel",
    code: "IL",
  },
  99: {
    name: "Italy",
    code: "IT",
  },
  100: {
    name: "Ivory Coast",
    code: "CI",
  },
  101: {
    name: "Jamaica",
    code: "JM",
  },
  102: {
    name: "Japan",
    code: "JP",
  },
  103: {
    name: "Jordan",
    code: "JO",
  },
  104: {
    name: "Kazakhstan",
    code: "KZ",
  },
  105: {
    name: "Kenya",
    code: "KE",
  },
  106: {
    name: "Kiribati",
    code: "KI",
  },
  107: {
    name: "Kuwait",
    code: "KW",
  },
  108: {
    name: "Kyrgyzstan",
    code: "KG",
  },
  109: {
    name: "Laos",
    code: "LA",
  },
  110: {
    name: "Latvia",
    code: "LV",
  },
  111: {
    name: "Lebanon",
    code: "LB",
  },
  112: {
    name: "Lesotho",
    code: "LS",
  },
  113: {
    name: "Liberia",
    code: "LR",
  },
  114: {
    name: "Libya",
    code: "LY",
  },
  115: {
    name: "Liechtenstein",
    code: "LI",
  },
  116: {
    name: "Lithuania",
    code: "LT",
  },
  117: {
    name: "Luxembourg",
    code: "LU",
  },
  118: {
    name: "Macau",
    code: "MO",
  },
  119: {
    name: "Macedonia",
    code: "MK",
  },
  120: {
    name: "Madagascar",
    code: "MG",
  },
  121: {
    name: "Malawi",
    code: "MW",
  },
  122: {
    name: "Malaysia",
    code: "MY",
  },
  123: {
    name: "Maldives",
    code: "MV",
  },
  124: {
    name: "Mali",
    code: "ML",
  },
  125: {
    name: "Malta",
    code: "MT",
  },
  126: {
    name: "Marshall Islands",
    code: "MH",
  },
  127: {
    name: "Martinique",
    code: "MQ",
  },
  128: {
    name: "Mauritania",
    code: "MR",
  },
  129: {
    name: "Mauritius",
    code: "MU",
  },
  131: {
    name: "Mexico",
    code: "MX",
  },
  133: {
    name: "Moldova",
    code: "MD",
  },
  134: {
    name: "Monaco",
    code: "MC",
  },
  135: {
    name: "Mongolia",
    code: "MN",
  },
  136: {
    name: "Montserrat",
    code: "MS",
  },
  137: {
    name: "Morocco",
    code: "MA",
  },
  138: {
    name: "Mozambique",
    code: "MZ",
  },
  139: {
    name: "Myanmar",
    code: "MM",
  },
  140: {
    name: "Namibia",
    code: "NA",
  },
  141: {
    name: "Nauru",
    code: "NR",
  },
  142: {
    name: "Nepal",
    code: "NP",
  },
  143: {
    name: "Netherlands",
    code: "NL",
  },
  144: {
    name: "Netherlands Antilles",
    code: "AN",
  },
  146: {
    name: "New Caledonia",
    code: "NC",
  },
  147: {
    name: "New Zealand",
    code: "NZ",
  },
  148: {
    name: "Nicaragua",
    code: "NI",
  },
  149: {
    name: "Niger",
    code: "NE",
  },
  150: {
    name: "Nigeria",
    code: "NG",
  },
  153: {
    name: "North Korea",
    code: "KP",
  },
  154: {
    name: "Norway",
    code: "NO",
  },
  155: {
    name: "Oman",
    code: "OM",
  },
  156: {
    name: "Pakistan",
    code: "PK",
  },
  158: {
    name: "Panama",
    code: "PA",
  },
  159: {
    name: "Papua New Guinea",
    code: "PG",
  },
  160: {
    name: "Paraguay",
    code: "PY",
  },
  161: {
    name: "Peru",
    code: "PE",
  },
  162: {
    name: "Philippines",
    code: "PH",
  },
  164: {
    name: "Poland",
    code: "PL",
  },
  166: {
    name: "Portugal",
    code: "PT",
  },
  168: {
    name: "Qatar",
    code: "QA",
  },
  169: {
    name: "Reunion",
    code: "RE",
  },
  170: {
    name: "Romania",
    code: "RO",
  },
  171: {
    name: "Russia",
    code: "RU",
  },
  172: {
    name: "Rwanda",
    code: "RW",
  },
  174: {
    name: "Saint Lucia",
    code: "LC",
  },
  175: {
    name: "Saint Vincent & Grenadines",
    code: "VC",
  },
  176: {
    name: "Samoa",
    code: "WS",
  },
  177: {
    name: "San Marino",
    code: "SM",
  },
  178: {
    name: "Saudi Arabia",
    code: "SA",
  },
  179: {
    name: "Senegal",
    code: "SN",
  },
  180: {
    name: "Seychelles",
    code: "SC",
  },
  181: {
    name: "Sierra Leone",
    code: "SL",
  },
  182: {
    name: "Singapore",
    code: "SG",
  },
  183: {
    name: "Slovakia",
    code: "SK",
  },
  184: {
    name: "Slovenia",
    code: "SI",
  },
  185: {
    name: "Solomon Islands",
    code: "SB",
  },
  186: {
    name: "Somalia",
    code: "SO",
  },
  187: {
    name: "South Africa",
    code: "ZA",
  },
  188: {
    name: "South Korea",
    code: "KR",
  },
  189: {
    name: "Spain",
    code: "ES",
  },
  190: {
    name: "Sri Lanka",
    code: "LK",
  },
  191: {
    name: "Sudan",
    code: "SD",
  },
  192: {
    name: "Suriname",
    code: "SR",
  },
  193: {
    name: "Swaziland",
    code: "SZ",
  },
  194: {
    name: "Sweden",
    code: "SE",
  },
  195: {
    name: "Switzerland",
    code: "CH",
  },
  196: {
    name: "Syria",
    code: "SY",
  },
  197: {
    name: "Tajikistan",
    code: "TJ",
  },
  198: {
    name: "Taiwan",
    code: "TW",
  },
  199: {
    name: "Tanzania",
    code: "TZ",
  },
  200: {
    name: "Thailand",
    code: "TH",
  },
  201: {
    name: "Togo",
    code: "TG",
  },
  203: {
    name: "Tonga",
    code: "TO",
  },
  204: {
    name: "Trinidad & Tobago",
    code: "TT",
  },
  205: {
    name: "Tunisia",
    code: "TN",
  },
  206: {
    name: "Turkey",
    code: "TR",
  },
  207: {
    name: "Turkmenistan",
    code: "TM",
  },
  208: {
    name: "Tuvalu",
    code: "TV",
  },
  209: {
    name: "Uganda",
    code: "UG",
  },
  210: {
    name: "Ukraine",
    code: "UA",
  },
  211: {
    name: "United Arab Emirates",
    code: "AE",
  },
  212: {
    name: "United Kingdom",
    code: "GB",
  },
  213: {
    name: "United States",
    code: "US",
  },
  214: {
    name: "Uruguay",
    code: "UY",
  },
  215: {
    name: "Uzbekistan",
    code: "UZ",
  },
  216: {
    name: "Vanuatu",
    code: "VU",
  },
  217: {
    name: "Vatican City",
    code: "VA",
  },
  218: {
    name: "Venezuela",
    code: "VE",
  },
  219: {
    name: "Vietnam",
    code: "VN",
  },
  220: {
    name: "British Virgin Islands",
    code: "VG",
  },
  224: {
    name: "Yemen",
    code: "YE",
  },
  225: {
    name: "Yugoslavia",
    code: "NA",
  },
  227: {
    name: "Zambia",
    code: "ZM",
  },
  228: {
    name: "Zimbabwe",
    code: "ZW",
  },
  230: {
    name: "Channel Islands",
    code: "NA",
  },
  231: {
    name: "Democratic Republic of the Congo",
    code: "CD",
  },
  232: {
    name: "Eritrea",
    code: "ER",
  },
  233: {
    name: "French Polynesia",
    code: "PF",
  },
  234: {
    name: "Saint Kitts & Nevis",
    code: "KN",
  },
  235: {
    name: "Turks & Caicos Islands",
    code: "TC",
  },
  236: {
    name: "Serbia",
    code: "RS",
  },
  237: {
    name: "Timor-Leste",
    code: "TL",
  },
  238: {
    name: "Montenegro",
    code: "ME",
  },
  239: {
    name: "Isle of Man",
    code: "IM",
  },
  240: {
    name: "Palestinian Authority",
    code: "PS",
  },
  241: {
    name: "Kosovo",
    code: "NA",
  },
  242: {
    name: "Curaçao",
    code: "CW",
  },
  243: {
    name: "Other",
    code: "NA",
  },
  244: {
    name: "Guernsey",
    code: "GG",
  },
  245: {
    name: "Jersey",
    code: "JE",
  },
}

const getFlagByCountryId = (countryId) => {
  const countryObj = getCountryObjById(countryId)
  return mappedEmojis[countryObj?.code]
}

const getCountryObjById = (countryId) => {
  return mappedCountryIds[countryId]
}

export { mappedEmojis, getFlagByCountryId, getCountryObjById }
